import React from 'react'
import { theme } from '../../constants/colors'
import { ThemeProvider } from '@material-ui/core/styles'
import Layout from '../../templates/LiveLayout'
import { Dashboard } from '../dashboard'
import { withErrorHandler } from '../../components/errorHandler'
import { PageRendererProps } from 'gatsby'

type Props = PageRendererProps

const DashboardPageTrainer = ({ location }: Props) => (
  <ThemeProvider theme={theme}>
    <Layout title="Dashboard" location={location}>
      <div style={{ marginTop: -40 }} />
      <Dashboard />
    </Layout>
  </ThemeProvider>
)

export default withErrorHandler(DashboardPageTrainer)
